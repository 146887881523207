<template>
  <div class="my_cer">
    <div v-if="CertificateList.length != 0">
      <div class="list">
        <div
          class="item font16 color66"
          v-for="item in CertificateList"
          :key="item.id"
        >
          <img src="../../assets/user/img.png" alt="" />

          <p>职业技能等级证书</p>
          <p>
            职业：{{ item.skillCategoryFirstName }}-{{
              item.skillCategorySecondName
            }}
          </p>
          <p>级别：{{ item.skillCategoryThirdName }}</p>
          <p>获取时间：{{ item.issue }}</p>
          <span @click="checkDetails(item)" class="download">下载证书</span>
        </div>
      </div>

      <div class="pagination_box">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalCount"
          @current-change="handelPage"
        >
        </el-pagination>
        <!-- :hide-on-single-page="true" -->
      </div>
    </div>
    <div class="empty" v-else>
      <div>
        <img src="../../assets/empty_box.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>

    <div class="my_cer" v-if="showDetails">
      <!-- <Certificate
        ref="certificate"
        :certificateData="certificateData"
        @getCanvasUrl="getCanvasUrl"
      ></Certificate> -->
      <certificatePageGuangDong
        ref="certificate"
        :certificateData="certificateData"
        @getCanvasUrl="getCanvasUrl"
        v-if="cerType==='广东'"
      ></certificatePageGuangDong>
      <certificatePageTianJing
        ref="certificate"
        :certificateData="certificateData"
        @getCanvasUrl="getCanvasUrl"
        v-if="cerType==='天津'"
      ></certificatePageTianJing>
    </div>
  </div>
</template>

<script>
// import Certificate from "../../components/certificare.vue";
import certificatePageGuangDong from "../../components/certificare_guangdong.vue";
import certificatePageTianJing from "../../components/certificare_tianjing.vue";
import {queryCertificateList} from "@/util/api";
export default {
  data() {
    return {
      showDetails: false,
      CertificateList: [],
      certificateData: null,
      empUrl: null,
      canvasImg: null,
      page: 1,
      limit: 12,
      companyId:'',
      cerType:'广东',
      cerBg:null
    };
  },
  mounted() {
    this.companyId = window.sessionStorage.getItem('companyId')
    this.getData();
    if(this.companyId == "c1b248d4c4a94b7392c297d433af6060"){
      this.cerType = '天津'
    }
  },
  components: {
    // Certificate,
    certificatePageGuangDong,
    certificatePageTianJing
  },
  methods: {
    checkDetails(item) {
      item.empUrl = this.empUrl;
      item.backgroundPicUrl = this.cerBg;
      this.certificateData = item;
      this.showDetails = true;
      setTimeout(() => {
        this.exportPic();
      }, 1000);
    },
    
    getCanvasUrl(url) {
      this.canvasImg = url;
    },
    getData() {
      this.$loading()
      queryCertificateList({
        page: this.page,
        limit: this.limit,
        domainUrl: window.location.hostname,
      }).then((res) => {
        this.totalCount = res.data.data.totalCount;
        this.CertificateList = res.data.data.list;
        if (res.data.empUrl) {
          this.empUrl = res.data.empUrl;
        }
        if (res.data.backgroundPicUrl) {
          // 获取当前公司的背景图
          this.cerBg = res.data.backgroundPicUrl
        }
        this.$loading().close()
      });
    },
    handelPage(val) {
      this.page = val;
      this.getData();
    },
    exportPic() {
      if (window.navigator.msSaveOrOpenBlob) {
        const bstr = atob(this.canvasImg.split(",")[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const blob = new Blob([u8arr]);
        window.navigator.msSaveOrOpenBlob(blob, "证书" + "." + "png");
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement("a");
        a.href = this.canvasImg;
        a.setAttribute("download", "证书");
        a.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my_cer {
  padding: 30px;
  .list {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    width: 262px;
    margin: 0 22px 20px;
    height: auto;
    padding-bottom: 15px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    p {
      padding: 10px 20px;
    }
    span {
      margin: 10px auto;
      display: block;
      width: 120px;
      height: 32px;
      border: 1px solid #5586e1;
      border-radius: 10px;
      color: #5586e1;
      line-height: 32px;
      text-align: center;
    }
    .download {
      cursor: pointer;
      transition: all 0.2s;
    }
    .download:hover {
      background-color: #5586e1;
      color: #ffffff;
    }
  }
}
.pagination_box {
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  width: 950px;
  margin: 0 auto;
  img {
    width: 400px;
    height: 400px;
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #666666;
  }
}
</style>
